<template>
  <section class="tabs-content personal__content personal__content tabs-content--active">
    <ReceiptList />
  </section>
</template>

<script>
import ReceiptList from '@/components/ReceiptList.vue';

export default {
  name: 'Receipts',
  components: {
    ReceiptList,
  },
};
</script>
